<template>
  <form :class="{ 'border-bottom pb-10 mb-5': selected.length > 0 }">
    <template v-if="group.type === SearchGroupTypes.MANAGED">
      <label class="font-bold block mb-1">{{ group.label }}</label>
      <div :class="{ 'border-bottom pb-10 mb-5': selected.length > 0 }">
        <el-select
          v-model="selected"
          multiple
          :placeholder="group.data && group.data.noSelectionLabel"
          class="w-full"
          @input="syncSelectedTools"
        >
          <el-option
            v-for="tool in group.tools"
            :key="`group-select-option-${tool.id}`"
            :label="tool.label"
            :value="tool.name"
          />
        </el-select>
      </div>
    </template>

    <el-collapse
      v-if="showLabel"
      class="border-top-0 border-bottom collapsible-group"
    >
      <el-collapse-item>
        <template #title>
          <div class="flex flex-col">
            <div
              class="cursor-pointer flex items-center justify-between pl-6 pr-4"
            >
              <span>{{ group.label }}</span>
            </div>
          </div>
        </template>

        <template v-for="tool in group.tools">
          <search-group-item
            v-if="showTool(tool)"
            :key="`search-tool-${tool.id}`"
            :tool="tool"
            :value="value"
            @input="$emit('input', $event)"
          />
        </template>
      </el-collapse-item>
    </el-collapse>

    <div v-if="!showLabel">
      <template v-for="tool in group.tools">
        <search-group-item
          v-if="showTool(tool)"
          :key="`search-tool-${tool.id}`"
          :tool="tool"
          :value="value"
          @input="$emit('input', $event)"
        />
      </template>
    </div>
  </form>
</template>

<script>
import { SearchGroupTypes, SearchToolTypes } from '@/constants';
import SearchGroupItem from '@/components/Core/SearchGroupItem';

export default {
  name: 'SearchGroup',
  components: {
    SearchGroupItem
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: null
    },
    group: {
      type: Object,
      required: true
    },
    showUnmanagedGroupLabel: Boolean
  },
  data() {
    let selected = [];

    if (this.group.data && this.group.data.defaultSelection) {
      selected = this.group.data.defaultSelection;
    }

    return {
      SearchGroupTypes,
      SearchToolTypes,
      selected
    };
  },
  computed: {
    showLabel() {
      return (
        this.group.type === SearchGroupTypes.UNMANAGED &&
        this.showUnmanagedGroupLabel
      );
    }
  },
  methods: {
    syncSelectedTools() {
      const value = this.value;

      const toolNames = Object.keys(this.value);

      for (let toolName of toolNames) {
        if (!this.selected.includes(toolName)) {
          delete value[toolName];
        }
      }

      this.$emit('input', value);
    },
    showTool(tool) {
      return (
        this.group.type === SearchGroupTypes.UNMANAGED ||
        this.selected.includes(tool.name)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.collapsible-group {
  border-top: none !important;
}
</style>
