<template>
  <div>
    <label class="font-bold block mb-1">{{ tool.label }}</label>

    <el-input
      v-if="tool.data.__typename === SearchToolDataTypes.TEXT_DATA"
      v-model="model"
    />

    <el-select
      v-else-if="tool.data.__typename === SearchToolDataTypes.SELECT_DATA"
      :value="model"
      :multiple="tool.data.isMulti"
      :filterable="tool.data.isMulti"
      :placeholder="tool.data.noSelectionLabel"
      :remote="isSelectRemote"
      :remote-method="selectRemoteMethod"
      :reserve-keyword="true"
      :collapse-tags="true"
      :no-match-text="selectRemoteNoMatchText"
      :no-data-text="selectRemoteNoMatchText"
      :loading="isLoadingRemoteOptions"
      class="w-full search-tool-select-data"
      @change="onSelectChange"
    >
      <el-option
        v-for="option in selectOptions"
        :key="`${tool.name}-select-option-${option.key}`"
        :label="option.value"
        :value="option.key"
      />
    </el-select>

    <el-checkbox-group
      v-else-if="tool.data.__typename === SearchToolDataTypes.BOOLEAN_DATA"
      v-model="checkboxGroupModel"
      class="flex"
      @change="onCheckboxGroupChange"
    >
      <el-checkbox-button
        v-if="!tool.data.hideOffButton"
        :label="tool.data.offLabel"
        :true-label="tool.data.offLabel"
        :value="
          typeof tool.data.offValue === undefined ? false : tool.data.offValue
        "
        class="flex-grow"
      />
      <el-checkbox-button
        :label="tool.data.onLabel"
        :true-label="tool.data.onLabel"
        :value="tool.data.onValue || true"
        class="flex-grow"
      />
    </el-checkbox-group>

    <el-date-picker
      v-else-if="tool.data.__typename === SearchToolDataTypes.DATE_RANGE_DATA"
      v-model="dateRange"
      type="daterange"
      start-placeholder="Start date"
      end-placeholder="End date"
      :picker-options="pickerOptions"
      format="MM/dd/yyyy"
      @change="onDateRangeChange"
    />

    <div
      v-else-if="
        tool.data.__typename === SearchToolDataTypes.DATE_CALENDAR_DATA
      "
      class="relative"
    >
      <flyte-picker
        :value="selectedDates"
        :config="datePickerConfig"
        :allow-toggle="tool.data.allowToggle"
        clearable
        allow-shortcuts
        @change="onDateCalendarChange"
        @config="onDateCalendarConfigChange"
      />
    </div>

    <div
      v-else-if="
        tool.data.__typename === SearchToolDataTypes.COUPLE_SELECT_DATA
      "
      class="flex"
    >
      <div class="w-1/2 pr-2">
        <el-select
          v-model="coupledKey"
          :multiple="tool.data.is_multi"
          :filterable="tool.data.is_multi"
          :placeholder="tool.data.noSelectionLabel"
          clearable
          class="w-full"
          @change="onCoupledChange"
        >
          <el-option
            v-for="option in tool.data.keyOptions"
            :key="`${tool.name}-select-key-option-${option.key}`"
            :label="option.value"
            :value="option.key"
          />
        </el-select>
      </div>

      <div class="w-1/2 pl-2">
        <el-select
          v-model="coupledValue"
          :multiple="tool.data.is_multi"
          :filterable="tool.data.is_multi"
          :placeholder="tool.data.noSelectionLabel"
          clearable
          class="w-full"
          @change="onCoupledChange"
        >
          <el-option
            v-for="option in tool.data.valueOptions"
            :key="`${tool.name}-select-value-option-${option.key}`"
            :label="option.value"
            :value="option.key"
          />
        </el-select>
      </div>
    </div>

    <div
      v-else-if="tool.data.__typename === SearchToolDataTypes.RANGE_DATA"
      class="h-12 px-2"
    >
      <div class="flex items-center justify-space-between">
        <div class="mr-5">
          <el-input
            v-mask.number="{
              min: tool.data.min,
              max: tool.data.max
            }"
            :value="sliderModel[0]"
            class="w-16 skinny"
            @change="
              (sliderModel = [+$event.replace(',', ''), sliderModel[1]]) &&
                onSliderChange(sliderModel)
            "
          />
        </div>
        <div class="flex flex-grow items-center">
          <vue-slider
            v-model="sliderModel"
            class="flex-grow"
            :min="+tool.data.min"
            :max="+tool.data.max"
            :interval="tool.data.interval || 1"
            :marks="!!tool.data.values"
            :lazy="true"
            :data="tool.data.values || undefined"
            :enable-cross="false"
            @change="onSliderChange"
          >
            <template #tooltip="{ value }">
              <div class="bg-blue text-white rounded px-3 py-1">
                <template v-if="tool.data.type === SearchDataTypes.CURRENCY">
                  {{ value | shortCurrency }}
                </template>
                <template v-else>
                  {{ value | shortNumber({ round: true }) }}
                </template>
              </div>
            </template>
            <template #label="{ active, value }">
              <div class="vue-slider-mark-label">
                <template v-if="tool.data.type === SearchDataTypes.CURRENCY">
                  {{ value | shortCurrency }}
                </template>
                <template v-else>
                  {{ value | shortNumber({ round: true }) }}
                </template>
              </div>
            </template>
          </vue-slider>
        </div>
        <div class="ml-5">
          <el-input
            v-mask.number="{ min: tool.data.min, max: tool.data.max }"
            :value="sliderModel[1]"
            class="w-16 skinny"
            @change="
              (sliderModel = [sliderModel[0], +$event.replace(',', '')]) &&
                onSliderChange(sliderModel)
            "
          />
        </div>
      </div>
    </div>

    <div
      v-else-if="tool.data.__typename === SearchToolDataTypes.TREE_SELECT_DATA"
    >
      <tree-select
        v-model="model"
        :multiple="tool.data.isMulti"
        :placeholder="tool.data.noSelectionLabel"
        value-consists-of="LEAF_PRIORITY"
        :options="mapTreeOptions"
      />
    </div>
  </div>
</template>

<script>
import FlytePicker from '@/components/Core/FlytePicker/FlytePicker';
import { SearchDataTypes, SearchToolDataTypes } from '@/constants';
import VueSlider from 'vue-slider-component';
import TreeSelect from '@riophae/vue-treeselect';

import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'vue-slider-component/theme/default.css';

import moment from 'moment';
import { momentTz, PICKER_SHORTCUTS } from '@/utils/date';
import { currency, date, dateDb, datetimeDb } from '@/utils/filters';

const MAX_SELECT_ITEMS = 30;

export default {
  components: {
    FlytePicker,
    TreeSelect,
    VueSlider
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    tool: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      SearchDataTypes,
      SearchToolDataTypes,
      coupledKey: null,
      coupledValue: null,
      checkboxGroupModel:
        this.tool.data.default !== null
          ? this.tool.data.default
            ? this.tool.data.onLabel
            : this.tool.data.offLabel
          : null,
      sliderModel: [0, 0],
      treeSelectModel: null,
      dateRange: null,
      selectedDates: [],
      isLoadingRemoteOptions: false,
      selectRemoteOptions: [],
      pickerOptions: {
        shortcuts: PICKER_SHORTCUTS.ALL
      },
      datePickerConfig: {
        inline: true,
        // For now this needs to be disabled, things get broken when trying to correct for date selection and disabling animation on the fly
        animate: false,
        mode: this.tool.data.allowMultiple ? 'multiple' : 'range',
        minDate: moment(this.tool.data.minDate || null).toISOString()
      }
    };
  },
  computed: {
    selectOptions() {
      return this.tool.data.isMulti && this.isSelectRemote
        ? this.selectRemoteOptions
        : this.tool.data.options;
    },
    model: {
      get() {
        if (this.value[this.tool.name]) {
          return this.value[this.tool.name].value;
        } else {
          return this.tool.data.isMulti ? [] : null;
        }
      },
      set(value) {
        // If value has been unset, then we want to set ourselves to undefined so the parent knows there is no search value
        if (value === undefined) {
          return this.$emit('input', {
            [this.tool.name]: undefined
          });
        }

        let searchValue = {};
        let displayValue = '';

        if (this.tool.data.useColumnNotNullCheck) {
          if (Array.isArray(value)) {
            let or = {};
            Object.values(value).forEach(val => {
              or[`data.${val}`] = { null: false };
            });
            searchValue[this.tool.name] = { or };
          } else {
            searchValue = { [`data.${value}`]: { null: false } };
          }
        } else if (this.tool.data.indexList) {
          let or = {};
          this.tool.data.indexList.forEach(index => {
            or[index] = value;
          });
          searchValue[this.tool.name] = { or };
        } else if (this.tool.data.indexFrom || !this.tool.data.index) {
          searchValue = value;
        } else {
          // Special case handling for sort expression
          if (this.tool.data.sortExpression) {
            searchValue[this.tool.data.index] = {
              column: this.tool.data.index,
              expression: this.tool.data.sortExpression,
              order: value
            };
          } else {
            searchValue[this.tool.data.index] = value;
            if (this.tool.data.secondaryFilter) {
              searchValue = {
                ...searchValue,
                ...this.tool.data.secondaryFilter
              };
            }
          }
        }

        // Basic display value parsing
        if (this.tool.data.indexFrom) {
          const fromValue = value[this.tool.data.indexFrom];
          const toValue = value[this.tool.data.indexTo];

          let start, end;

          if (toValue) {
            // The end date is the LATEST date we will allow our start date to be
            end = fromValue['<='];
            // The start date is the EARLIEST date we will allow our end date to be
            start = toValue['>='];
          } else if (fromValue) {
            start = fromValue['>='];
            end = fromValue['<='];
          }

          if (start || end) {
            if (
              this.tool.data.__typename === SearchToolDataTypes.DATE_RANGE_DATA
            ) {
              displayValue = `${date(start)} to ${date(end)}`;
            } else {
              displayValue = `${start} to ${end}`;
            }
          }
        } else {
          switch (this.tool.data.__typename) {
            case SearchToolDataTypes.SELECT_DATA:
              if (value.length && value.length > 2) {
                displayValue = value.length + ' Selected';
              } else {
                for (let option of this.tool.data.options) {
                  if (Array.isArray(value)) {
                    if (value.includes(option.key)) {
                      displayValue += (displayValue ? ', ' : '') + option.value;
                    }
                  } else {
                    if (option.key === value) {
                      displayValue += (displayValue ? ', ' : '') + option.value;
                    }
                  }
                }
              }
              break;

            case SearchToolDataTypes.TREE_SELECT_DATA:
              if (value.length > 1) {
                displayValue = value.length + ' Selected';
              } else {
                displayValue =
                  value.length === 1 ? value[0].replace(/;/g, ', ') : '';
              }
              break;

            case SearchToolDataTypes.RANGE_DATA:
              var from = value['>='] || 0;
              var to = value['<='];

              if (this.tool.data.type === SearchDataTypes.CURRENCY) {
                from = currency(from);
                to = to !== undefined ? currency(to) : undefined;
              }

              displayValue = from + (to !== undefined ? ' to ' + to : '');
              break;

            default:
              displayValue = Array.isArray(value) ? value.join(', ') : value;
              break;
          }
        }

        this.$emit('input', {
          [this.tool.name]: {
            tool: this.tool,
            label: this.tool.label,
            value,
            displayValue,
            searchValue
          }
        });
      }
    },
    selectRemoteNoMatchText() {
      return 'No matching items found';
    },
    isSelectRemote() {
      return this.tool.data.options.length > MAX_SELECT_ITEMS;
    },
    mapTreeOptions() {
      return this.tool.data.options.map(this.mapTreeOptionsChildren);
    }
  },
  mounted() {
    if (this.model) {
      this.loadDefaultsFromModel();
    } else {
      this.setDefaultValues();
    }
  },
  methods: {
    onCheckboxGroupChange(value) {
      if (this.checkboxGroupModel === this.tool.data.onLabel) {
        this.model = this.tool.data.onValue || true;
      } else if (
        [this.tool.data.offLabel, this.tool.data.offValue].includes(
          this.checkboxGroupModel
        )
      ) {
        this.model = this.tool.data.hideOffButton
          ? undefined
          : this.tool.data.offValue || false;
      } else {
        this.model = undefined;
      }
    },
    setDefaultValues() {
      switch (this.tool.data.__typename) {
        case SearchToolDataTypes.BOOLEAN_DATA:
          if (this.tool.data.default !== null) {
            this.model = this.checkboxGroupModel === this.tool.data.onLabel;
          }
          break;

        case SearchToolDataTypes.SELECT_DATA:
          if (this.tool.data.defaultSelection) {
            this.model = this.tool.data.defaultSelection[0];
          }
          break;
      }

      const dates = this.tool.data.dates;

      if (dates) {
        this.dateRange = [
          momentTz(dates[0]).toISOString(),
          dates[1] ? momentTz(dates[1]).toISOString() : undefined
        ];
      }
    },
    loadDefaultsFromModel() {
      const fromValue = this.model[this.tool.data.indexFrom];
      const toValue = this.model[this.tool.data.indexTo];
      // const indexValue = this.model[this.tool.data.index];

      switch (this.tool.data.__typename) {
        case SearchToolDataTypes.DATE_RANGE_DATA:
          if (fromValue) {
            if (toValue) {
              if (fromValue['>=']) {
                this.dateRange = [
                  moment(fromValue['>=']),
                  moment(toValue['<='])
                ];
              } else {
                // Sometimes this will be reversed depending on how the date search query works (ie: finding a time box that overlaps with a time box filter)
                this.dateRange = [
                  moment(toValue['>=']),
                  moment(fromValue['<='])
                ];
              }
            } else {
              this.dateRange = [
                moment(fromValue['>=']),
                moment(fromValue['<='])
              ];
            }
          } else if (this.model) {
            if (this.model['>='] || this.model['<=']) {
              this.dateRange = [
                moment(this.model['>=']),
                moment(this.model['<='])
              ];
            }
          }
          break;

        case SearchToolDataTypes.DATE_CALENDAR_DATA:
          if (this.model) {
            this.selectedDates = [this.model['>='], this.model['<=']];
          }
          break;

        default:
          // TODO: we cannot set the model here otherwise it will trigger an input, thus probably causing an input change / emit event loop in some cases
          // if (indexValue !== null && indexValue !== undefined) {
          //   this.model = indexValue;
          // }
          break;
      }
    },
    mapTreeOptionsChildren(option) {
      const data = {
        id: option.key,
        label: option.value
      };

      if (option.options) {
        data.children = option.options.map(this.mapTreeOptionsChildren);
      }

      return data;
    },
    onCoupledChange() {
      if (this.coupledKey && this.coupledValue) {
        this.model = { [this.coupledKey]: this.coupledValue };
      } else this.model = null;
    },
    onSliderChange(val) {
      this.model = {
        '>=': val[0],
        '<=': val[1]
      };
    },
    onSelectChange(value) {
      let newValue;

      if (this.tool.data.isMulti) {
        newValue = value.length === 0 ? undefined : value;

        if (newValue && this.tool.data.emptyAsNull) {
          newValue = newValue.map(v => (v === '' ? null : v));
        }
      } else {
        newValue = value === null ? undefined : value;

        if (this.tool.data.emptyAsNull && newValue === '') {
          newValue = null;
        }
      }

      this.model = newValue;
    },
    onDateCalendarChange(selectedDates) {
      this.selectedDates = selectedDates;

      if (selectedDates && selectedDates.length > 0) {
        if (this.datePickerConfig.mode === 'multiple') {
          this.model = {
            '=': selectedDates.map(d => dateDb(d))
          };
        } else {
          if (selectedDates.length > 1) {
            this.model = {
              '>=': dateDb(selectedDates[0]),
              '<=': dateDb(selectedDates[1])
            };
          } else {
            this.model = {
              '=': dateDb(selectedDates[0])
            };
          }
        }
      } else {
        this.model = undefined;
      }
    },
    onDateCalendarConfigChange(config) {
      // Only allow changing the mode (ie: range / multiple)
      if (config.mode !== this.datePickerConfig.mode) {
        this.datePickerConfig.mode = config.mode;
        this.onDateCalendarChange([]);
      }
    },
    onDateRangeChange(val) {
      if (val) {
        let startDate = val[0];
        let endDate = val[1];

        if (this.tool.data.type === SearchDataTypes.DATETIME) {
          startDate = datetimeDb(moment(startDate).startOf('day'));
          endDate = datetimeDb(moment(endDate).endOf('day'));
        } else {
          startDate = dateDb(startDate);
          endDate = dateDb(endDate);
        }

        if (this.tool.data.indexTo === this.tool.data.indexFrom) {
          this.model = {
            [this.tool.data.indexFrom]: {
              '>=': startDate,
              '<=': endDate
            }
          };
        } else {
          this.model = {
            [this.tool.data.indexFrom]: {
              '<=': endDate
            },
            [this.tool.data.indexTo]: {
              '>=': startDate
            }
          };
        }
      } else {
        this.model = undefined;
      }
    },
    selectRemoteMethod(query) {
      let options = [];

      if (query !== '') {
        this.isLoadingRemoteOptions = true;

        // Load this asynchronously so we don't block the script
        setTimeout(() => {
          for (let item of this.tool.data.options) {
            if (item.value.toLowerCase().indexOf(query.toLowerCase()) > -1) {
              options.push(item);

              if (options.length >= MAX_SELECT_ITEMS) {
                break;
              }
            }
          }
        }, 50);
      }

      this.isLoadingRemoteOptions = false;
      this.selectRemoteOptions = options;
    }
  }
};
</script>
