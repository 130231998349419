<script>
import { cloneDeep } from 'lodash';

export default {
  props: {
    filter: {
      type: Object,
      default: null
    },
    searchTools: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    cleanFilter() {
      let filter = { ...this.filter };

      for (let searchTool of this.searchTools) {
        for (let tool of searchTool.tools) {
          if (tool.data) {
            if (tool.data.indexList) {
              for (let index of tool.data.indexList) {
                filter[index] = undefined;
              }
            } else {
              filter[tool.data.index] = undefined;
            }
          }
        }
      }

      return filter;
    }
  },
  methods: {
    mapSearchGroups(groups) {
      let filter = this.cleanFilter;
      let sort = {};

      const SORT_GROUP = 'Sort Group';

      let filterGroups = cloneDeep(groups);
      let sortGroups = groups[SORT_GROUP];
      delete filterGroups[SORT_GROUP];

      if (filterGroups) {
        Object.values(filterGroups).forEach(group => {
          Object.values(group).forEach(toolData => {
            filter = {
              ...filter,
              ...(toolData.searchValue || {})
            };

            if (
              toolData.value === null ||
              (Array.isArray(toolData.value) && toolData.value.length === 0)
            ) {
              for (let prop in toolData.searchValue) {
                delete filter[prop];
              }
            }
          });
        });
      }

      if (sortGroups) {
        let sortTools = Object.values(sortGroups);
        sortTools.forEach(tool => {
          sort = {
            ...sort,
            ...tool.searchValue
          };
        });
      }

      return { filter, sort };
    }
  }
};
</script>
