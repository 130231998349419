<template>
  <div>
    <search-group
      v-if="tool.type === SearchGroupTypes.MANAGED"
      v-model="model"
      :group="tool"
    />
    <search-tool
      v-else-if="tool.__typename === SearchToolTypes.SEARCH_TOOL"
      v-model="model"
      :tool="tool"
      class="mb-4"
    />
  </div>
</template>

<script>
import SearchTool from './SearchTool';
import { SearchGroupTypes, SearchToolTypes } from '@/constants';
import { pickBy } from 'lodash';
import SearchGroup from '@/components/Core/SearchGroup';

export default {
  name: 'SearchGroupItem',
  components: {
    SearchTool,
    SearchGroup
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    tool: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      SearchGroupTypes,
      SearchToolTypes
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(
          'input',
          pickBy({ ...this.value, ...value }, v => v !== undefined)
        );
      }
    }
  }
};
</script>
